import './App.css';
import styled from 'styled-components';

function App() {
  return (
    <Wrapper>
      <Card>
        <Background />
        <PersonalDetails>
          <Name>Derek Wilmot</Name>
          <Location>Prosper, Texas</Location>
        </PersonalDetails>
        <Image />
        <Numbers>
          <Logo>
            <a href="https://www.instagram.com/derekwilmot/" target="_blank" rel="noreferrer" alt="Purchase Wired Differently Book">
            <Book />
              </a>
            </Logo>
            <Logo>
            <a href="https://github.com/CoffeeNCoding" target="_blank" rel="noreferrer" alt="ToyQueen Website">
            <ToyQueen />
              </a>
            </Logo>
            <Logo>
              <a href="https://www.linkedin.com/in/derekwilmot/" target="_blank" rel="noreferrer" alt="Keri's Linkedin">
            <Social />
              </a>
            </Logo>
        </Numbers>
      </Card>
    </Wrapper>);
}

export default App;

const Wrapper =styled.div`
background-image: url("https://ak.picdn.net/shutterstock/videos/31325755/thumb/1.jpg");
background-size: cover;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
`
const Card =styled.div`
background-color: white;
display: flex;
flex-direction: column;
justify-content: flex-start;
border-radius: 10px;
width: 450px;
height: 450px;
position: relative;

`
const Background =styled.div`
background-image: url("http://phpography.com/images/free-high-tech-wallpaper-1.jpg");
background-size: cover;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
width: 100%;
height: 33%;
`


const PersonalDetails =styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
width: 100%;
height: 38%;

`
const Name =styled.h1`
font-size: 35px;
margin-bottom: -15px;
color: Black;

`
const Location =styled.h4`
font-size: 15px;
color: black;
`

const Image =styled.div`
background-image: url("https://media-exp1.licdn.com/dms/image/C4E03AQGVKUL9TxGm9g/profile-displayphoto-shrink_400_400/0/1582822335791?e=1635379200&v=beta&t=JsTtV0bf0apIPqov6v2BX56FSw6uw2WANurAVPAlIYc");
background-size: contain;
border-radius: 50%;
border: 3px #1C3041 solid;
left: 150px;
top: 90px;
width: 120px;
height: 120px;
position: absolute;

`
const Numbers =styled.div`
display: flex;
justify-content: space-around;
border-top: 1px navy solid;

`
const Logo =styled.div`
display: flex;
flex-direction: column;
justify-content: center;
margin-top: 20px;


`
const Book =styled.div`
background-image: url("https://assets.stickpng.com/images/580b57fcd9996e24bc43c521.png");
background-size: contain;
background-repeat: no-repeat;
display: flex;
flex-direction: column;
width: 80px;
height: 80px;

&:hover{
transform: scale(1.75);
transition: all 0.5s ease-out;

}
`;
const ToyQueen =styled.div`
background-image: url("img/github_icon.png");
background-size: contain;
background-repeat: no-repeat;
display: flex;
flex-direction: column;
width: 90px;
height: 90px;

&:hover{
transform: scale(1.5);
transition: all 0.5s ease-out;
}
`;
const Social =styled.div`
background-image: url("https://gartman.com/wp-content/uploads/2020/08/57-571935_linkedin-icon-vector-png-linkedin-circle-logo-transparent.jpg");
background-size: contain;
background-repeat: no-repeat;
background-color: white;
display: flex;
flex-direction: column;
width: 90px;
height: 90px;

&:hover{
transform: scale(1.2);
transition: all 0.5s ease-out;
}

`;